.page {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--brand-dark);
  padding-top: 4em;
  min-height: 100%;
}

.preloader {
  display: flex;
  width: 100%;
  background: transparent url(preloader-static.svg) center center no-repeat;
}

.container {
    width: 100%;
    display: flex;

    border: var(--test-border-dotted);
    border-color: blue;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--brand-dark);
}

.headerContainer {
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}  

.userbar {
  display: flex;
  align-items: center;
  gap: .5em;
}

.name {
  color: var(--brand-light);
  font-size: 1.1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icons {
  display: flex;
  gap: 1.5em;
}

.iconLink {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: none; 
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;

  border: var(--test-border-dotted);
  border-color: yellow;
}

.iconLink img {
  display: block;
  width: 100%;
  height: 100%;
}

.text {
  color: var(--brand-light);
  font-size: 1.1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* text-transform: uppercase; */


  border: var(--test-border-dotted);
  border-color: yellow;
}



@media screen and (min-width: 1024px) {
    .page {
      background: #fff url(desktop-page-bg.svg) no-repeat top left;
    }

    .preloader {
      background: transparent url(preloader-static-dark.svg) center center no-repeat;
    }    

    .container {
      max-width: 768px;
    }

    .header {
      box-shadow: 0px 3px 10px 0px rgba(89, 91, 121, 0.50);
    }
}