.button {
    cursor: pointer;
    padding: .5em 2em;
    background: var(--brand-dark);
    color: var(--brand-light);  
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 3px 10px 0px rgba(var(--brand-light-rgb), 0.15);
}

.light {
    background: var(--brand-light);
    color: var(--brand-dark);
}

.button:active {
    /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
    box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
}

.big {
    padding: .8em 2em;
    font-size: 1.15em;
}

.small {
    padding: .5em 1em;
    font-size: .85em;
}

@media (min-width: 1024px) {
    .button {
        box-shadow: none;
        transition: all 0.2s ease; 
    }

    .button:hover {
        /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
        box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
    }
}