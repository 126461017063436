.empty {
    padding: 1.5em;
    font-weight: 300;
    font-size: 1.25em;
    text-align: center;
    color: rgba(var(--brand-dark-rgb), .65);
}

.header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-top: 1em;
}

.header h1 {
    font-size: 1.25em;
    line-height: 1;
    font-weight: 600;
    color: var(--brand-dark);
}

.table {
    color: var(--brand-dark);
    border-spacing: 0;
    border-collapse: separate;
    border: .25em solid rgba(var(--brand-dark-rgb), .25);
    border-radius: 1em;    
    table-layout: fixed;
}

.table tr {
    background-color: transparent;
    border: 1px solid #ddd;
    padding: .35em;
}

.table > tbody {
    font-size: .85em;
}

.table > tbody > tr {
    cursor: pointer;
    transition: all 0.2s ease; 
}

.table > tbody > tr:hover,
.table > tbody > tr:active {
    opacity: .45;
}

.table th,
.table td {
    padding: .625em;
}

tr:not(:last-child)>td {
    border-bottom: .1em solid rgba(var(--brand-dark-rgb), .15);
}

.table th {
    font-weight: bold;
    font-size: 1em;
    letter-spacing: .1em;
    text-transform: uppercase;
    border-bottom: .25em solid rgba(var(--brand-dark-rgb), .25);
    text-align: left;
}


@media screen and (max-width: 600px) {
    .empty {
        color: rgba(var(--brand-light-rgb), .65);
    }

    .header h1 {
        color: var(--brand-light);
    }

    .table {
        border: none;
    }

    .table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .table > tbody {
        font-size: 1.15em;
    }

    .table tr {
        display: block;
        margin-bottom: .625em;
        background-color: #fff;
        border-radius: 1em;
    }

    .table > tbody > tr:hover,
    .table > tbody > tr:active {
        opacity: .85;
    }    

    .table td {
        display: block;
        font-size: .8em;
        text-align: right;
        border-bottom: 1px solid #ddd;
    }

    .table td::before {
        /*
      * aria-label has no advantage, it won't be read inside a .table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .table td:last-child {
        border-bottom: 0;
    }
}