.alert {
    display: flex;
    flex-direction: column;
    gap: .25em;
    padding: 1em;
    border: 3px solid rgba(var(--brand-dark-rgb), .65);
    border-radius: 12px;
    color: var(--brand-dark);
    background-color: rgba(255, 255, 255, 0.85);
}

.title {
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
}

.message {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Types */
.danger {
    color: var(--brand-danger);
    border-color: rgba(var(--brand-danger), .65);
    animation-name: ShakeDanger;
    animation-fill-mode: forward;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
}

@keyframes ShakeDanger {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes ShakeSuccess {
    0% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(0.375rem);
    }

    30% {
        transform: translateY(-0.375rem);
    }

    45% {
        transform: translateY(0.375rem);
    }

    60% {
        transform: translateY(-0.375rem);
    }

    75% {
        transform: translateY(0.375rem);
    }

    90% {
        transform: translateY(-0.375rem);
    }

    100% {
        transform: translateY(0);
    }
}


.success {
    color: var(--brand-success);
    border-color: rgba(var(--brand-success), .65);
    animation-name: ShakeSuccess;
    animation-fill-mode: forward;
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
}