:root {
    --radius: 1em;
}

.wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-radius: var(--radius) var(--radius) 0px 0px;
    border: 0.5px solid rgba(36, 31, 51, 0.03);
    background: #F3F3F3;
    box-shadow: 0px 3px 10px 0px rgba(158, 157, 189, 0.25);
}

.link {
    border-radius: var(--radius);
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
}

.container {
    display: flex;
    gap: 1em;
}

.avatar {
    width: 48px;
    height: 48px;
    background: rgba(var(--brand-dark-rgb), 0.85);
    border: 2px solid rgba(var(--brand-dark-rgb), 0.25);
    border-radius: 50px;
    background-position: center center;
    background-repeat: no-repeat;
}

.info {
    display: flex;
    flex-direction: column;
    gap: .45em;
}

.description {
    color: rgba(var(--brand-dark-rgb), 0.45);
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
}

.amount {
    display: flex;
    align-items: baseline;
    gap: .2em;
}

.value {
    color: rgba(var(--brand-text-rgb), 0.75);
    font-size: 1.6em;
    font-style: normal;
    font-weight: 900;
    line-height: 1;
}

.currency {
    color: rgba(54, 56, 73, 0.35);
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 900;
    line-height: 1;
}

.historyButton {
    width: 30px;
    height: 30px;
}
  
.historyButton img {
    display: block;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 430px) {
    .avatar {
        width: 64px;
        height: 64px;
    } 

    .info {
        gap: .35em;
    }    

    .description {
        padding-top: .4em;
    }

    .amount {
        gap: .25em;
    }

    .value {
        font-size: 2em;
    }

    .currency { 
        font-size: 1.5em;
    }   

    .historyButton {
        width: 35px;
        height: 35px;
    }
}


@media screen and (min-width: 1024px) {
    .wallet {
        box-shadow: none;
        transition: all 0.2s ease;
    } 
    .wallet:hover {
        box-shadow: 0px 0px 0px .2em rgba(158, 157, 189, .5);
        /* box-shadow: 0px 3px 10px 0px rgba(158, 157, 189, .25); */
    } 
}