:root {
    --wm-item-border: 1px solid rgba(var(--brand-dark-rgb), .15);
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.title {
    font-size: 1.35em;
    font-weight: 700;
    color: var(--brand-dark);
    border-bottom: var(--wm-item-border);
    padding: 1em;
}

.menu li {
    margin: 0;
    padding: 0;
}

.menu li a {
    display: flex;
    align-items: center;
    gap: .7em;
    text-decoration: none;
    font-size: 1.125em;
    color: rgba(var(--brand-text-rgb), .85);
    padding: 1em;
    font-weight: 400;
    background-color: #fff;
    border-bottom: var(--wm-item-border);
}

.close {
    margin-left: auto;
    padding-right: .25em;
    opacity: .5;
}

.icon {
    width: 2em;
    height: 2em;
    fill: rgba(var(--brand-dark-rgb), 0.9);
}

.arrow {
    margin-left: auto;
    width: 1em;
    height: 1em;
}

.menu li a:hover, .menu li a:active {
    background-color: rgba(var(--brand-dark-rgb), .1);
}