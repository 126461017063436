.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    border: var(--test-border-dotted);
    border-color: green;
}

.form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    padding: 2em 1em;
    padding-bottom: 3em;
    background: #fff url(mobile-page-bg.svg) no-repeat top right;
    border-radius: 1em;

    border: var(--test-border-dotted);
    border-color: red;
}

.header h1 {
    color: var(--brand-dark);
    font-size: 1.8em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
}

.header h2 {
    color: rgba(var(--brand-dark-rgb), 0.65);
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.group {
    display: flex;
    flex-direction: column;
}

.group input {
    width: 100%;
    border-radius: 25px;
    border: 2px solid rgba(var(--brand-dark-rgb), 0.65);
    background: var(--brand-light);
    padding: .5em 1em;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2em;
    color: var(--brand-text);
    transition: all 0.2s ease;
}

.group input::placeholder {
    color: #B7B7B7;
}

.group input:focus {
    border: 2px solid var(--brand-dark);
    background: #FBFBFB;
    /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
    box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
    outline: none;
}

.form[disabled] input {
    opacity: .65;
}

.group label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    color: rgba(var(--brand-dark-rgb), 0.85);
    margin-bottom: .25em;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1em;
    gap: 1em;
}

.footer span, .footer a {
    color: rgba(var(--brand-dark-rgb), 0.85);
    font-size: .85em;
}

.footer a {
    text-decoration: underline;
    cursor: pointer;
    color: var(--brand-dark);
    font-weight: 500;
}

.footer a:hover {
    opacity: .65;
}

.footer button {
    cursor: pointer;
    padding: .8em 2em;
    background: var(--brand-dark);
    color: rgba(255, 255, 255, 0.85);
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.25);
}

.footer button:active {
    /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
    box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
}

.form[disabled] button {
    opacity: .65;
}

@media (min-width: 1024px) {
    .form {
        background: transparent;
        border-radius: 0;
        max-width: 500px;
        gap: 1.5em;
    }

    .header h2 {
        font-size: 1.2em;
    }

    .group {
        flex-direction: row;
        align-items: center;
    }

    .group label {
        min-width: 20%;
        margin-bottom: 0;
    }

    .footer {
        align-items: flex-start;
    }

    .footer button {
        cursor: pointer;
        padding: .5em 2em;
        font-size: 1.25em;
        box-shadow: none;
        transition: all 0.2s ease;
    }

    .footer button:hover {
        /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
        box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
    }
}